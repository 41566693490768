export const getError = (error) => {
  return error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
};

export const generateSlug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'àáäăâèéëêìîïîòóöôùúüûñçșț·/_,:;';
  var to = 'aaaaaeeeeiiiioooouuuuncst------';
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

export const redactori = [
  'Iordache Marin',
  'Andreea Filipescu',
  'George Prăjescu',
  'Redacția',
];
export const categorii = [
  { text: 'Politică', value: 'politica' },
  { text: 'Actualitate', value: 'actualitate' },
  { text: 'Administrație Publică', value: 'administratie-publica' },
  { text: 'Monden', value: 'monden' },
  { text: 'Business', value: 'business' },
  { text: 'Advertoriale', value: 'advertoriale' },
  { text: 'Economie', value: 'economie' },
  { text: 'Cultură', value: 'cultura' },
  { text: 'Sport', value: 'sport' },
  { text: 'Sci-Tech', value: 'sci-tech' },
  { text: 'Evenimente', value: 'evenimente' },
];
