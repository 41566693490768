import React, { useContext } from 'react';
import { HStack, Stack } from '@chakra-ui/react';
import {
  CancelSub,
  ChangePassword,
  DeleteAccount,
  InfoCont,
  ListaZiare,
  StickyMenu,
} from '../index';
import { PageTitle } from '../../components';
import { Store } from '../../utils/Store';

const border = 'rgba(0, 0, 0, 0.5)';
const ProfilUser = ({ api }) => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  return (
    <HStack
      w={'full'}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      flexWrap={'wrap'}
      minH={'100vh'}
      gap={'3rem'}
      px={{ base: '1rem', lg: '3rem' }}
      pb={'2rem'}
    >
      <PageTitle title={'Setările contului'} />
      <Stack
        w={{ base: 'full', lg: '300px' }}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        position={'relative'}
      >
        <StickyMenu />
      </Stack>
      <Stack
        alignItems={'center'}
        justifyContent={'center'}
        w={{ base: 'full', lg: 'calc(100% - 350px)' }}
        gap={'4rem'}
      >
        <InfoCont api={api} border={border} />
        <ChangePassword api={api} border={border} />
        <ListaZiare api={api} border={border} />
        {!userInfo.isAdmin && <CancelSub api={api} border={border} />}
        <DeleteAccount api={api} border={border} />
      </Stack>
    </HStack>
  );
};

export default ProfilUser;
