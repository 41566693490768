import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Select,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  chakra,
} from '@chakra-ui/react';
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { EditorContext } from '../../components/EditorContext';
import { Store } from '../../utils/Store';
import {
  categorii,
  generateSlug,
  getError,
  redactori,
} from '../../utils/Utils';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };
    case 'UPLOAD_REQUEST':
      return { ...state, loadingUpload: true, errorUpload: '' };
    case 'UPLOAD_SUCCESS':
      return {
        ...state,
        loadingUpload: false,
        errorUpload: '',
      };
    case 'UPLOAD_FAIL':
      return { ...state, loadingUpload: false, errorUpload: action.payload };
    default:
      return state;
  }
};
const border = 'rgba(0, 0, 0, 0.5)';

const EditArticol = ({ api }) => {
  const navigate = useNavigate();

  const { initEditor, editorInstanceRef } = useContext(EditorContext);
  const editorRef = useRef(null);

  useEffect(() => {
    if (!editorRef.current) {
      initEditor();
      editorRef.current = true;
    }
  }, [initEditor]);

  const params = useParams();
  const { id: articolId } = params;
  const { state } = useContext(Store);
  const { userInfo } = state;

  const [
    {
      //  loading,
      error,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const [titlu, setTitlu] = useState('');
  const [descriereCoperta, setDescriereCoperta] = useState('');
  const [slug, setSlug] = useState('');
  const [pozaCoperta, setPozaCoperta] = useState({
    src: '',
    alt: '',
  });
  const [pozeArticol, setPozeArticol] = useState([]);
  const [etichete, setEtichete] = useState([]);
  const [categorie, setCategorie] = useState('');
  const [redactor, setRedactor] = useState('Redacția');
  const [dataActualizare, setDataActualizare] = useState('');
  const [continut, setContinut] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(
          `${api}/api/articol/editare/${articolId}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        setTitlu(data.titlu);
        setDescriereCoperta(data.descriereCoperta);
        setPozaCoperta(data.pozaCoperta);
        setPozeArticol(data.pozeArticol);
        setEtichete(data.etichete);
        setCategorie(data.categorie);
        setRedactor(data.redactor);
        setPozaCoperta(data.pozaCoperta);
        setContinut(data.continut);
        dispatch({ type: 'FETCH_SUCCES' });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err });
        toast.error(err);
      }
    };
    fetchData();
  }, [api, articolId, userInfo.token]);

  useEffect(() => {
    if (continut.length > 0) {
      editorInstanceRef.current.render({
        blocks: continut,
      });
    }
  }, [continut, editorInstanceRef]);

  // handle etichete
  const [input, setInput] = useState('');

  const tagExists = (tag) => etichete.includes(tag);

  const handleAdd = (tagsToAdd) => {
    if (etichete.length > 5) {
      toast.info('Ai atins limita recomandata de etichete');
    } else {
      const validTags = tagsToAdd
        .map((e) => e.trim())
        .filter((tag) => !tagExists(tag));
      const newTags = [...etichete, ...validTags];
      setEtichete(newTags);
    }
    setInput('');
  };

  const handleDelete = (tag) => {
    const newTags = etichete.filter((e) => e !== tag);
    setEtichete(newTags);
  };

  const handleKey = (e) => {
    if (['Enter', 'Tab', ','].includes(e.key)) {
      e.preventDefault();
      if (input !== '') {
        handleAdd([input]);
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const pastedData = e.clipboardData.getData('text');
    const pastedTags = pastedData.split(',').slice(0, 6);
    handleAdd(pastedTags);
  };
  // end of etichete

  const uploadImages = async (e, poze) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    try {
      dispatch({ type: 'UPLOAD_REQUEST' });
      const { data } = await axios.post(
        `${api}/api/articol/poze`,
        bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      dispatch({ type: 'UPLOAD_SUCCESS' });
      if (poze) {
        setPozeArticol([...pozeArticol, { src: data.fileLink }]);
        toast.success('Pozele au fost urcate cu succes.');
      } else {
        setPozaCoperta({ src: data.fileLink });
        toast.success('Poza a fost urcată cu succes.');
      }
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPLOAD_FAIL', payload: getError(err) });
    }
  };

  useEffect(() => {
    setSlug(generateSlug(titlu));
    const getToday = () => {
      var day = new Date().getDate();
      var month = new Date().getMonth() + 1;
      var year = new Date().getFullYear();
      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      const today = day + '/' + month + '/' + year;

      setDataActualizare(today);
    };
    getToday();
  }, [titlu]);

  const deleteFileHandler = async (index) => {
    setPozeArticol(pozeArticol.filter((x, i) => i !== index));
  };

  const addDescriere = (e, index) => {
    const updatedPoze = pozeArticol.map((item, i) =>
      i === index ? { ...item, descriereImagine: e } : item
    );

    // Update the state with the new array
    setPozeArticol(updatedPoze);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const data = await editorInstanceRef.current.save();

    const articolText = data.blocks;
    try {
      dispatch({ type: 'UPDATE_REQUEST' });
      await axios
        .put(
          `${api}/api/articol/editare/${articolId}`,
          {
            titlu,
            slug,
            continut: articolText,
            categorie,
            etichete,
            dataActualizare,
            redactor,
            pozaCoperta,
            descriereCoperta,
            pozeArticol,
          },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        )
        .then((response) => {
          toast.success(`${response.data.message}`);
        });

      dispatch({ type: 'UPDATE_SUCCESS' });
      navigate('/user/articole');
    } catch (err) {
      toast.error(getError(error));
      dispatch({
        type: 'UPDATE_FAIL',
      });
    }
  };

  const cancelHandler = () => {
    navigate('/admin/articole');
  };
  return (
    <Stack
      w={'full'}
      maxW={'80rem'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      minH={'100vh'}
      gap={'4rem'}
      px={{ base: '1rem', lg: '3rem' }}
      py={'2rem'}
    >
      <Heading as={'h1'}>Editeaza articolul </Heading>

      <chakra.form w={'full'} onSubmit={submitHandler}>
        <Stack gap={'2rem'}>
          <HStack alignItems={'flex-start'}>
            <FormControl>
              <FormLabel>Titlul:</FormLabel>
              <Input
                borderColor={border}
                type="text"
                value={titlu}
                onChange={(e) => setTitlu(e.target.value)}
              />
            </FormControl>
            {/* etichete */}
            <FormControl>
              <FormLabel>Etichete:</FormLabel>
              <Input
                borderColor={border}
                type={'text'}
                placeholder={'Introdu etichetele'}
                onPaste={(e) => handlePaste(e)}
                onKeyDown={(e) => handleKey(e)}
                onChange={(e) => setInput(e.target.value)}
                value={input}
              />
              <Wrap spacing={1} mt={3}>
                {etichete.map((text) => (
                  <Tag
                    key={text}
                    borderRadius={'full'}
                    variant={'solid'}
                    colorScheme="gray"
                  >
                    <TagLabel>{text}</TagLabel>
                    <TagCloseButton onClick={() => handleDelete(text)} />
                  </Tag>
                ))}
              </Wrap>
            </FormControl>
          </HStack>
          <HStack>
            {/* Categorie */}
            <FormControl w={'300px'} isRequired>
              <FormLabel>Categorie:</FormLabel>
              <Select
                borderColor={border}
                w={'full'}
                placeholder="Alege categoria"
                value={categorie}
                onChange={(e) => setCategorie(e.target.value)}
              >
                {categorii.map((item, index) => (
                  <chakra.option
                    color={'#010101'}
                    key={index}
                    value={item.value}
                  >
                    {item.text}
                  </chakra.option>
                ))}
              </Select>
            </FormControl>
            {/* Redactor */}
            <FormControl w={'300px'} isRequired>
              <FormLabel>Redactor:</FormLabel>
              <Select
                borderColor={border}
                w={'full'}
                placeholder="Alege redactorul"
                onChange={(e) => setRedactor(e.target.value)}
              >
                <chakra.option color={'#010101'}>Otilia Zamfir</chakra.option>{' '}
                <chakra.option color={'#010101'}>
                  {userInfo.nume + ' ' + userInfo.prenume}
                </chakra.option>
                {redactori.map((item, index) => (
                  <chakra.option color={'#010101'} key={index} value={item}>
                    {item}
                  </chakra.option>
                ))}
              </Select>
            </FormControl>
          </HStack>
          {/* Stack poze */}
          <HStack>
            <FormControl>
              <FormLabel>Poza Coperta:</FormLabel>
              <Input
                borderColor={border}
                type="file"
                value={''}
                onChange={(e) => uploadImages(e, false)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Descriere imagine:</FormLabel>
              <Input
                borderColor={border}
                type="text"
                value={descriereCoperta}
                onChange={(e) => setDescriereCoperta(e.target.value)}
              />
            </FormControl>
          </HStack>
          {/* <Stack w={'350px'}>
            <FormControl>
              <FormLabel>Poze Galerie:</FormLabel>

              <Input
                borderColor={border}
                type="file"
                placeholder="Alege imaginea de coperta"
                onChange={(e) => uploadImages(e, true)}
                isDisabled={pozeArticol.length === 2}
              />
            </FormControl>
            <Stack>
              {pozeArticol.length > 0 ? (
                pozeArticol.map((item, index) => (
                  <HStack key={index}>
                    <Image w={'100px'} src={item.src} alt="" />{' '}
                    <Input
                      borderColor={border}
                      type="text"
                      value={item.descriereImagine}
                      onChange={(e) => addDescriere(e.target.value, index)}
                    />
                    <IconButton
                      fontSize={'1.5rem'}
                      bg={'brand.100'}
                      color={'brand.300'}
                      border={'1px solid'}
                      borderColor={'brand.300'}
                      _hover={'none'}
                      onClick={() => deleteFileHandler(index)}
                      icon={<BiTrash />}
                    />
                  </HStack>
                ))
              ) : (
                <MessageBox>Nu sunt poze în galerie</MessageBox>
              )}
            </Stack>
          </Stack> */}
          <HStack>
            <FormControl>
              <FormLabel>Continutul Articolului:</FormLabel>
              <Box
                border={'1px solid #000'}
                borderRadius={'1rem'}
                borderColor={border}
                w={{ base: '350px', lg: 'full' }}
              >
                <div id={'editor'}></div>
              </Box>
            </FormControl>
          </HStack>
          <FormControl>
            <Button
              type="submit"
              bg={'gradient.buton'}
              _hover={{ transform: 'translateY(-5px)' }}
            >
              Editeaza
            </Button>
            <Button
              bg={'red'}
              color={'brand.100'}
              onClick={cancelHandler}
              _hover={{ transform: 'translateY(-5px)' }}
            >
              Anulează
            </Button>
          </FormControl>
        </Stack>
      </chakra.form>
    </Stack>
  );
};

export default EditArticol;
