import React from 'react';
import {
  FaHouse,
  FaClipboardCheck,
  FaRegNewspaper,
  FaCartShopping,
} from 'react-icons/fa6';
import { FaCog, FaEdit } from 'react-icons/fa';
import { HStack, Icon, Image, Stack, Text, Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';

const Sidebar = ({ toggle, isOpen }) => {
  const menuItems = [
    {
      link: '/',
      icon: FaHouse,
      text: 'Acasă',
    },
    {
      link: '/articol-nou',
      icon: FaEdit,
      text: 'Crează articol',
    },
    {
      link: '/user/articole',
      icon: FaClipboardCheck,
      text: 'Articolele mele',
    },
    {
      link: '/publicatii',
      icon: FaRegNewspaper,
      text: 'Publicații online',
    },
    {
      link: '/pachete',
      icon: FaCartShopping,
      text: 'Abonamente',
    },
  ];
  return (
    <Stack
      w={'300px'}
      h={'100vh'}
      bgColor={'brand.201'}
      p={'1rem'}
      borderRadius={'0 2rem 2rem 0'}
      transition={'all .4s ease-in-out'}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
      position={'fixed'}
      zIndex={1000}
      left={{ base: isOpen ? 0 : '-400px', lg: isOpen ? 0 : '-400px' }}
    >
      <HStack
        w={'100%'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'wrap'}
      >
        <Image w={'100px'} src={'/images/Logo.png'} alt={'VOX Press'} />
        <Tooltip label="Meniu">
          <CloseIcon color={'brand.400'} boxSize={8} onClick={toggle} />
        </Tooltip>
      </HStack>
      <Stack w={'full'} gap={'3rem'}>
        {menuItems.map((item) => (
          <Link key={item.text} to={item.link} onClick={toggle}>
            <HStack
              fontSize={'1.25rem'}
              fontWeight={'bold'}
              color={'brand.400'}
            >
              <Icon fontSize={'1.75rem'} as={item.icon} />
              &nbsp;<Text>{item.text}</Text>
            </HStack>
          </Link>
        ))}
      </Stack>
      <HStack>
        <Tooltip label="Setari cont">
          <Link to={'/user/setari'}>
            <Icon color={'brand.400'} fontSize={'1.75rem'} as={FaCog} />
          </Link>
        </Tooltip>
      </HStack>
    </Stack>
  );
};

export default Sidebar;
