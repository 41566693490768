import { Heading, Image, Stack, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useReducer } from 'react';
import { LoadingBox } from '../components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Store } from '../utils/Store';
import { getError } from '../utils/Utils';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_STATUS':
      return {
        ...state,
        status: action.payload,
        loading: false,
      };

    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

const PaymentSuccess = ({ api }) => {
  const navigate = useNavigate();
  const [{ status }, dispatch] = useReducer(reducer, {
    loading: false,
    error: '',
    ziare: [],
    limitaZiare: 0,
  });
  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const userPack = localStorage.getItem('userPack')
      ? JSON.parse(localStorage.getItem('userPack'))
      : null;
    const fetchPayStatus = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const status = await axios.get(`${api}/api/stripe/checkPayment`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        console.log(status.data);
        dispatch({ type: 'FETCH_STATUS', payload: status.data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    fetchPayStatus();

    const addLimits = async () => {
      await axios.put(
        `${api}/api/users/register/pachet`,
        {
          pachetId: userPack._id,
          limitaArticole: userPack.limitaArticole,
          limitaZiare: userPack.limitaZiare,
          dataStart: userPack.dataStart,
          dataEnd: userPack.dataEnd,
          isYearly: userPack.isYearly,
          counterArticole: userPack.counterArticole,
        },
        { headers: { authorization: `Bearer ${userInfo.token}` } }
      );
      localStorage.removeItem('userPack');
    };
    status === 'success' && addLimits();
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer);
  }, [api, navigate, status, userInfo.token]);

  return (
    <Stack
      w={'full'}
      px={{ base: '1rem', lg: '3rem' }}
      minH={'100vh'}
      gap={'4rem'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Image w={'300px'} src={'/images/Logo.png'} alt={'VOX Press'} />
      <Heading
        as={'h1'}
        fontSize={'2rem'}
        w={{ base: '300px', lg: '80rem' }}
        textAlign={'center'}
      >
        Plata a fost efectuată cu succes. <br />
        Limitele pachetului selectat au fost creditate în contul dumneavoastră.
      </Heading>
      <Stack alignItems={'center'} justifyContent={'center'}>
        <LoadingBox /> <Text fontWeight={'bold'}>Redirectionare</Text>
      </Stack>
    </Stack>
  );
};

export default PaymentSuccess;
