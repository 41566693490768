import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Image,
  Input,
  Select,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  chakra,
} from '@chakra-ui/react';
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
// import Parser from 'html-react-parser';
import { categorii, generateSlug, getError, redactori } from '../utils/Utils';
import axios from 'axios';
import { Store } from '../utils/Store';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { EditorContext } from '../components/EditorContext';
import { MessageBox } from '../components';
import { BiTrash } from 'react-icons/bi';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_USERINFO':
      return {
        ...state,
        limitaArticole: action.payload.limitaArticole,
        counterArticole: action.payload.counterArticole,
        oneTime: action.payload.oneTime,
        links: action.payload.listaZiare,
      };
    case 'CREATE_REQUEST':
      return { ...state, loadingCreate: true };
    case 'CREATE_SUCCESS':
      return {
        ...state,
        loadingCreate: false,
      };
    case 'CREATE_FAIL':
      return { ...state, loadingCreate: false };
    case 'UPLOAD_REQUEST':
      return { ...state, loadingUpload: true, errorUpload: '' };
    case 'UPLOAD_SUCCESS':
      return {
        ...state,
        loadingUpload: false,
        errorUpload: '',
      };
    case 'UPLOAD_FAIL':
      return { ...state, loadingUpload: false, errorUpload: action.payload };
    default:
      return state;
  }
};

const border = 'rgba(0, 0, 0, 0.5)';
const CreazaArticol = ({ api }) => {
  const [{ error, counterArticole, limitaArticole, links }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: '',
    });

  const { initEditor, editorInstanceRef } = useContext(EditorContext);
  const editorRef = useRef(null);

  useEffect(() => {
    if (!editorRef.current) {
      initEditor();
      editorRef.current = true;
    }
  }, [initEditor]);

  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;

  const [titlu, setTitlu] = useState('');
  const [descriereCoperta, setDescriereCoperta] = useState('');
  const [slug, setSlug] = useState('');
  const [pozaCoperta, setPozaCoperta] = useState('');
  const [pozeArticol, setPozeArticol] = useState([]);
  const [etichete, setEtichete] = useState([]);
  const [categorie, setCategorie] = useState('');
  const [redactor, setRedactor] = useState('Redacția');
  const [dataPostare, setDataPostare] = useState('');
  const [dataActualizare, setDataActualizare] = useState('');

  const uploadImages = async (e, poze) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    try {
      dispatch({ type: 'UPLOAD_REQUEST' });
      const { data } = await axios.post(
        `${api}/api/articol/poze`,
        bodyFormData,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      dispatch({ type: 'UPLOAD_SUCCESS' });
      if (poze) {
        setPozeArticol([...pozeArticol, { src: data.fileLink }]);
      } else {
        setPozaCoperta({ src: data.fileLink });
      }
      toast.success('Poza a fost urcată cu succes.');
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPLOAD_FAIL', payload: getError(err) });
    }
  };

  // handle etichete
  const [input, setInput] = useState('');

  const tagExists = (tag) => etichete.includes(tag);

  const handleAdd = (tagsToAdd) => {
    if (etichete.length > 5) {
      toast.info('Ai atins limita recomandata de etichete');
    } else {
      const validTags = tagsToAdd.filter((tag) => !tagExists(tag));
      const newTags = [...etichete, ...validTags];
      setEtichete(newTags);
    }
    setInput('');
  };

  const handleDelete = (tag) => {
    const newTags = etichete.filter((e) => e !== tag);
    setEtichete(newTags);
  };

  const handleKey = (e) => {
    if (['Enter', ',', ' ', 'Spacebar'].includes(e.key)) {
      e.preventDefault();
      if (input !== '') {
        handleAdd([input]);
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const pastedData = e.clipboardData.getData('text');
    const pastedTags = pastedData.split(',').slice(0, 6);
    handleAdd(pastedTags);
  };
  // end of etichete
  useEffect(() => {
    const fetchInfo = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`${api}/api/users/userInfo`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });

        dispatch({ type: 'FETCH_USERINFO', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    fetchInfo();
  }, [api, userInfo.token]);

  useEffect(() => {
    setSlug(generateSlug(titlu));
    const getToday = () => {
      var day = new Date().getDate();
      var month = new Date().getMonth() + 1;
      var year = new Date().getFullYear();
      if (day < 10) {
        day = '0' + day;
      }
      if (month < 10) {
        month = '0' + month;
      }
      const today = day + '/' + month + '/' + year;

      setDataActualizare(today);
      setDataPostare(today);
    };
    getToday();
  }, [titlu]);

  const valid = true;
  const views = 0;
  const userId = userInfo._id;

  const deleteFileHandler = async (index) => {
    setPozeArticol(pozeArticol.filter((x, i) => i !== index));
  };
  const addDescriere = (e, index) => {
    const updatedPoze = pozeArticol.map((item, i) =>
      i === index ? { ...item, descriereImagine: e } : item
    );

    // Update the state with the new array
    setPozeArticol(updatedPoze);
  };
  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      dispatch({ type: 'CREATE_REQUEST' });

      const data = await editorInstanceRef.current.save();
      const continut = data.blocks;

      await axios.post(
        `${api}/api/articol/creaza-articol`,
        {
          titlu,
          slug,
          continut,
          categorie,
          etichete,
          dataPostare,
          dataActualizare,
          userId,
          views,
          redactor,
          pozaCoperta,
          pozeArticol,
          descriereCoperta,
          valid,
          counterArticole,
          links: links.map((item) => item.link),
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      toast.success(`Articol creat cu succes`);

      dispatch({ type: 'CREATE_SUCCESS' });
      navigate('/user/articole?page=1');
    } catch (err) {
      toast.error(getError(error));
      dispatch({
        type: 'CREATE_FAIL',
      });
    }
  };
  return (
    <Stack
      w={'full'}
      maxW={'80rem'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      minH={'100vh'}
      gap={'2rem'}
      px={{ base: '1rem', lg: '3rem' }}
      py={'2rem'}
    >
      <Heading as={'h1'}>Crează un articol nou</Heading>
      <HStack fontSize={'1rem'}>
        <MessageBox>
          {userInfo.isAdmin
            ? 'Sky iz dă limit'
            : `Ai folosit
          ${counterArticole + '/' + limitaArticole}
          articole`}
        </MessageBox>
      </HStack>
      <chakra.form w={'full'} onSubmit={submitHandler}>
        <Stack gap={'2rem'}>
          <HStack
            alignItems={'flex-start'}
            flexWrap={{ base: 'wrap', lg: 'nowrap' }}
          >
            <FormControl isRequired>
              <FormLabel>Titlul:</FormLabel>
              <Input
                borderColor={border}
                type="text"
                value={titlu}
                onChange={(e) => setTitlu(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Etichete:</FormLabel>
              <Input
                borderColor={border}
                type={'text'}
                placeholder={'Introdu etichetele'}
                onPaste={(e) => handlePaste(e)}
                onKeyDown={(e) => handleKey(e)}
                onChange={(e) => setInput(e.target.value)}
                value={input}
              />
              <Wrap spacing={1} mt={3}>
                {etichete.map((text) => (
                  <Tag
                    key={text}
                    borderRadius={'full'}
                    variant={'solid'}
                    colorScheme="gray"
                  >
                    <TagLabel>{text}</TagLabel>
                    <TagCloseButton onClick={() => handleDelete(text)} />
                  </Tag>
                ))}
              </Wrap>
            </FormControl>
          </HStack>
          <HStack flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
            <FormControl w={'300px'} isRequired>
              <FormLabel>Categorie:</FormLabel>
              <Select
                borderColor={border}
                w={'full'}
                placeholder="Alege categoria"
                onChange={(e) => setCategorie(e.target.value)}
              >
                {categorii.map((item, index) => (
                  <chakra.option
                    color={'#010101'}
                    key={index}
                    value={item.value}
                  >
                    {item.text}
                  </chakra.option>
                ))}
              </Select>
            </FormControl>
            <FormControl w={'300px'} isRequired>
              <FormLabel>Redactor:</FormLabel>
              <Select
                borderColor={border}
                w={'full'}
                placeholder="Alege redactorul"
                onChange={(e) => setRedactor(e.target.value)}
              >
                <chakra.option color={'#010101'}>Otilia Zamfir</chakra.option>{' '}
                <chakra.option color={'#010101'}>
                  {userInfo.nume + ' ' + userInfo.prenume}
                </chakra.option>
                {redactori.map((item, index) => (
                  <chakra.option color={'#010101'} key={index} value={item}>
                    {item}
                  </chakra.option>
                ))}
              </Select>
            </FormControl>
          </HStack>
          {/* Stack poze */}
          <HStack flexWrap={{ base: 'wrap', lg: 'nowrap' }}>
            <FormControl>
              <FormLabel>Poza Coperta:</FormLabel>

              <Input
                borderColor={border}
                type="file"
                placeholder="Alege imaginea de coperta"
                onChange={(e) => uploadImages(e, false)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Descriere imagine:</FormLabel>
              <Input
                borderColor={border}
                type="text"
                value={descriereCoperta}
                onChange={(e) => setDescriereCoperta(e.target.value)}
              />
            </FormControl>
          </HStack>
          {/* <Stack w={'350px'}>
            <FormControl>
              <FormLabel>Poze Galerie:</FormLabel>

              <Input
                borderColor={border}
                type="file"
                placeholder="Alege imaginea de coperta"
                onChange={(e) => uploadImages(e, true)}
                isDisabled={pozeArticol.length === 2}
              />
            </FormControl>
            <Stack>
              {pozeArticol.length > 0 ? (
                pozeArticol.map((item, index) => (
                  <HStack key={index}>
                    <Image w={'100px'} src={item.src} alt="" />{' '}
                    <Input
                      borderColor={border}
                      type="text"
                      value={item.descriereImagine}
                      onChange={(e) => addDescriere(e.target.value, index)}
                    />
                    <IconButton
                      fontSize={'1.5rem'}
                      bg={'brand.100'}
                      color={'brand.300'}
                      border={'1px solid'}
                      borderColor={'brand.300'}
                      _hover={'none'}
                      onClick={() => deleteFileHandler(index)}
                      icon={<BiTrash />}
                    />
                  </HStack>
                ))
              ) : (
                <MessageBox>Nu sunt poze în galerie</MessageBox>
              )}
            </Stack>
          </Stack> */}
          <HStack>
            <FormControl isRequired>
              <FormLabel>Continutul articolului:</FormLabel>
              <Box
                border={'1px solid #000'}
                borderRadius={'1rem'}
                borderColor={border}
                w={{ base: '350px', lg: 'full' }}
              >
                <div id={'editor'}></div>
              </Box>
            </FormControl>
          </HStack>
          <FormControl>
            <Button
              isDisabled={
                userInfo.isAdmin ? false : counterArticole === limitaArticole
              }
              type="submit"
              bg={'brand.300'}
              color={'brand.100'}
              _hover={{ transform: 'translateY(-5px)' }}
            >
              Creaza articol
            </Button>
          </FormControl>
        </Stack>
      </chakra.form>
    </Stack>
  );
};

export default CreazaArticol;
