import { Button, HStack, Heading, Stack, Switch, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Store } from '../../../utils/Store';

const DeleteAccount = ({ api }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { state } = useContext(Store);
  const { userInfo } = state;
  const deleteHandler = async () => {
    localStorage.removeItem('userInfo');
    if (!confirmDelete) {
      toast.error('Va rugam confirmati faptul ca doriti stergerea contului!');
    } else {
      await axios.delete(`${api}/api/users/delete-user`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });

      window.open('https://app.voxpress.ro/register', '_self');
    }
  };
  return (
    <Stack
      id={'deleteAccount'}
      w={'full'}
      borderRadius={'1.5rem'}
      bg={'brand.100'}
      boxShadow={'0 0 32px rgba(0,0,0,0.3)'}
      px={'3rem'}
      py={'2rem'}
      gap={'3rem'}
    >
      <Stack>
        <Heading as={'h2'} fontSize={'1.75rem'}>
          Ștergere cont
        </Heading>
        <Text>
          Odată șters contul, nu se va putea recupera. Vă rugăm să fiți sigur.{' '}
        </Text>
      </Stack>
      <HStack w={'full'} justifyContent={'space-between'}>
        <HStack>
          <Switch
            size={'lg'}
            onChange={() => setConfirmDelete((prev) => !prev)}
          />
          <Stack gap={0}>
            <Heading as={'h3'} fontSize={'1.2rem'}>
              Confirm că
            </Heading>
            <Text fontSize={'.8rem'}>Vreau să îmi șterg contul</Text>
          </Stack>
        </HStack>
        <Button
          bg={'#ff0000'}
          _hover={{ bg: '#8f0000' }}
          color={'textLight'}
          fontWeight={'bold'}
          textTransform={'uppercase'}
          onClick={deleteHandler}
          boxShadow={'0px 5px 24px rgba(0, 0, 0, 0.3)'}
        >
          șterge contul
        </Button>
      </HStack>
    </Stack>
  );
};

export default DeleteAccount;
