import { Heading, Stack } from '@chakra-ui/react';
import React from 'react';

const PageTitle = (props) => {
  const { title } = props;
  return (
    <Stack
      className="pageTitle"
      w={'full'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Heading
        as={'h1'}
        color={'brand.201'}
        fontSize={'2.5rem'}
        textAlign={'center'}
      >
        {title}
      </Heading>
    </Stack>
  );
};

export default PageTitle;
