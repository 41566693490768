import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { Store } from '../utils/Store';
import { Link } from 'react-router-dom';

const Meniu = ({ toggle, isOpen }) => {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
  };
  return (
    <HStack
      w={'full'}
      justifyContent={'space-between'}
      zIndex={100}
      px={{ base: '1rem', lg: '3rem' }}
      py={'1rem'}
    >
      <HamburgerIcon boxSize={8} onClick={toggle} />
      <HStack>
        <Menu>
          <MenuButton as={'button'}>
            <HStack alignItems={'center'}>
              <Avatar name={userInfo.nume + ' ' + userInfo.prenume} />
              <ChevronDownIcon fontSize={'1.5rem'} />
            </HStack>
          </MenuButton>
          <MenuList>
            {userInfo.isAdmin && (
              <>
                <Link to={'/admin/articole'}>
                  <MenuItem>Lista Articole</MenuItem>
                </Link>
                <Link to={'/admin/utilizatori'}>
                  <MenuItem>Lista Utilizatori</MenuItem>
                </Link>
                <Link to={'/admin/abonamente'}>
                  <MenuItem>Lista Abonamente</MenuItem>
                </Link>
                <MenuDivider borderColor={'brand.201'} />
              </>
            )}
            <Link to={'/user/publicatii'}>
              <MenuItem>Schimbă publicațiile</MenuItem>
            </Link>
            <Link to={'/user/setari'}>
              <MenuItem>Setări cont</MenuItem>
            </Link>
            <MenuDivider borderColor={'brand.201'} />

            <MenuItem onClick={signoutHandler}>Deconectare</MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </HStack>
  );
};

export default Meniu;
