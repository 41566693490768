import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import RegisterSteps from '../../components/RegisterSteps';
import {
  CardZiareReg,
  LoadingBox,
  MessageBox,
  PageTitle,
} from '../../components';
import { Store } from '../../utils/Store';
import axios from 'axios';
import { getError } from '../../utils/Utils';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_STATUS':
      return {
        ...state,
        status: action.payload,
      };
    case 'FETCH_PUBLICATII':
      return {
        ...state,
        loadingZiare: false,
        ziare: action.payload,
      };
    case 'FETCH_LIMITE':
      return {
        ...state,
        loadingUser: false,
        limitaZiare: action.payload.limitaZiare,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

const ZiareReg = ({ api }) => {
  const navigate = useNavigate();
  const [{ loadingZiare, loadingUser, error, ziare, status }, dispatch] =
    useReducer(reducer, {
      loading: false,
      error: '',
      ziare: [],
      limitaZiare: 0,
    });

  const { state } = useContext(Store);
  const { userInfo } = state;
  const [limita, setLimita] = useState(0);

  useEffect(() => {
    const userPack = localStorage.getItem('userPack')
      ? JSON.parse(localStorage.getItem('userPack'))
      : null;
    setLimita(userPack.limitaZiare);
    const addPack = async () => {
      await axios.put(
        `${api}/api/users/register/pachet`,
        {
          limitaArticole: userPack.limitaArticole,
          limitaZiare: userPack.limitaZiare,
          dataStart: userPack.dataStart,
          dataEnd: userPack.dataEnd,
          isYearly: userPack.isYearly,
          counterArticole: userPack.counterArticole,
        },
        { headers: { authorization: `Bearer ${userInfo.token}` } }
      );
    };
    status === 'success' && addPack();
  }, [api, status, userInfo.token]);

  useEffect(() => {
    const fetchPayStatus = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const status = await axios.get(`${api}/api/stripe/checkPayment`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_STATUS', payload: status.data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`${api}/api/ziar`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_PUBLICATII', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };

    fetchData();
    const fetchUser = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`${api}/api/users/userInfo`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setLimita(data.limitaZiare);
        dispatch({ type: 'FETCH_LIMITE', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    fetchUser();
    fetchPayStatus();
    setPublicatiiAlese([]);
  }, [api, userInfo.token]);

  const [publicatiiAlese, setPublicatiiAlese] = useState([]);

  const handleAdd = (item) => {
    if (publicatiiAlese.includes(item)) {
      setPublicatiiAlese(
        publicatiiAlese.filter((publicatie) => publicatie !== item)
      );
    } else {
      if (publicatiiAlese.length < limita) {
        setPublicatiiAlese([...publicatiiAlese, item]);
      } else {
        toast.info('Ai atins limita de publicații');
      }
    }
  };
  const handleSelectAll = () => {
    if (publicatiiAlese.length < limita) {
      const toateZiarele = ziare.map((ziar) => ziar);
      const capacitateValabila = limita - publicatiiAlese.length;
      const ziareAdaugat = toateZiarele.slice(0, capacitateValabila);
      setPublicatiiAlese([...publicatiiAlese, ...ziareAdaugat]);
    }
  };

  const handleDeselectAll = () => {
    setPublicatiiAlese([]);
  };

  const handleFinishSetup = async () => {
    if (publicatiiAlese.length === 0) {
      toast.info('Trebuie sa selectați cel putin o publicație!');
    } else {
      localStorage.removeItem('userPack');
      await axios
        .put(
          `${api}/api/users/register/ziare`,
          { publicatiiAlese },
          { headers: { authorization: `Bearer ${userInfo.token}` } }
        )
        .then((response) =>
          localStorage.setItem('userInfo', JSON.stringify(response.data))
        )
        .catch((error) => toast.error(error.message));

      navigate('/');
    }
  };

  return (
    <Stack
      className="publicatiiReg"
      w={'full'}
      px={{ base: '1rem', lg: '3rem' }}
      minH={'100vh'}
      gap={'4rem'}
      alignItems={'center'}
    >
      <RegisterSteps step1 step2 step3 step4 />
      <PageTitle title="Alege publicatiile care ti se potrivesc" />
      {loadingUser ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox status={'error'}>{error}</MessageBox>
      ) : (
        <HStack
          w={'full'}
          maxW={'100rem'}
          fontSize={{ base: '1rem', lg: '1.5rem' }}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
        >
          <HStack gap={'2rem'}>
            <Text>
              Poți alege maxim <strong>{limita} </strong>
              publicații
            </Text>

            <HStack>
              <Button
                w={'fit-content'}
                borderRadius={'1rem'}
                bg={'brand.201'}
                color={'brand.100'}
                _hover={'none'}
                onClick={handleFinishSetup}
              >
                Adaugă publicațiile
              </Button>
              <Text>
                {publicatiiAlese.length}/{limita}
              </Text>
            </HStack>
          </HStack>

          <HStack>
            <Button
              borderRadius={'1rem'}
              bg={'gradient.buton'}
              onClick={handleSelectAll}
            >
              Selectează maximul
            </Button>
            <Button
              borderRadius={'1rem'}
              bg={'gradient.buton'}
              onClick={handleDeselectAll}
            >
              Deselectează totul
            </Button>
          </HStack>
        </HStack>
      )}
      {loadingZiare ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox status={'error'}>{error}</MessageBox>
      ) : (
        ziare && (
          <HStack
            w={'full'}
            maxW={'100rem'}
            flexWrap={'wrap'}
            justifyContent={'center'}
            gap={'2rem 1rem'}
          >
            {ziare.map((item) => (
              <CardZiareReg
                key={item._id}
                ziar={item}
                onCheck={handleAdd}
                publicatiiAlese={publicatiiAlese}
                limita={limita}
              />
            ))}
          </HStack>
        )
      )}
    </Stack>
  );
};

export default ZiareReg;
