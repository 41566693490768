import { Button, ButtonGroup, Heading, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { PageTitle, PfForm, PjForm } from '../../components';
import axios from 'axios';
import RegisterSteps from '../../components/RegisterSteps';

const BillingData = ({ api }) => {
  const [listaJudete, setListaJudete] = useState([]);
  const [billingType, setBillingType] = useState('pf');

  useEffect(() => {
    const judeteRomania = async () => {
      const { data } = await axios.get('https://roloca.coldfuse.io/judete');
      setListaJudete(data);
    };
    judeteRomania();
  }, []);

  const changeBillingHandler = (type) => {
    setBillingType(type);
  };
  return (
    <Stack
      w={'full'}
      px={{ base: '1rem', lg: '3rem' }}
      minH={'100vh'}
      gap={'4rem'}
      alignItems={'center'}
    >
      <PageTitle title="Date de facturare" />
      <RegisterSteps step1 />
      <Stack alignItems={'center'} justifyContent={'center'}>
        <Heading as={'h2'} fontSize={'1.5rem'} textAlign={'center'}>
          Modul de facturare
        </Heading>
        <ButtonGroup mb={'1rem'}>
          <Button
            onClick={() => changeBillingHandler('pf')}
            bg={billingType === 'pf' ? 'brand.201' : 'brand.100'}
            color={billingType === 'pf' ? 'brand.100' : 'brand.201'}
            _hover={{ color: 'brand.100', bg: 'brand.200' }}
          >
            Persoana Fizică
          </Button>
          <Button
            onClick={() => changeBillingHandler('pj')}
            bg={billingType === 'pj' ? 'brand.201' : 'brand.100'}
            color={billingType === 'pj' ? 'brand.100' : 'brand.201'}
            _hover={{ color: 'brand.100', bg: 'brand.200' }}
          >
            Persoana Juridică
          </Button>
        </ButtonGroup>
        <Text color={'gray.600'}>
          Facturile vor fi transmise pe email-ul folosit la înregistrare.
        </Text>
      </Stack>
      {billingType === 'pf' ? (
        <PfForm listaJudete={listaJudete} api={api} />
      ) : (
        <PjForm listaJudete={listaJudete} api={api} />
      )}
    </Stack>
  );
};

export default BillingData;
