import { createContext, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';
import Header from '@editorjs/header';
import Underline from '@editorjs/underline';
import ChangeCase from 'editorjs-change-case';
import Strikethrough from '@sotaproject/strikethrough';
import AlignmentBlockTune from 'editorjs-text-alignment-blocktune';

export const EditorContext = createContext();

const EditorContextProvider = (props) => {
  const editorInstanceRef = useRef(null);
  const initEditor = () => {
    const editor = new EditorJS({
      holder: 'editor',
      placeholder: 'Introdu continutul articolului',
      tools: {
        textAlignment: {
          class: AlignmentBlockTune,
          config: {
            default: 'left',
          },
        },
        paragraph: {
          class: Paragraph,
          tune: ['textAlignment'],
        },
        list: {
          class: List,
        },
        header: {
          class: Header,
          inlineToolbar: true,
          tunes: ['textAlignment'],
          config: {
            placeholder: 'Introdu un subtitlu',
            levels: [2, 3, 4, 5, 6],
            defaultLevel: 2,
          },
        },
        underline: {
          class: Underline,
        },
        strikethrough: {
          class: Strikethrough,
        },
        changeCase: {
          class: ChangeCase,
        },
      },
    });
    editorInstanceRef.current = editor;
  };
  return (
    <EditorContext.Provider value={{ initEditor, editorInstanceRef }}>
      {props.children}
    </EditorContext.Provider>
  );
};

export default EditorContextProvider;
