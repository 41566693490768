import { extendTheme } from '@chakra-ui/react';

export const myTheme = extendTheme({
  colors: {
    brand: {
      100: '#ffffff',
      101: '#d1d1d1',
      201: '#5f34c2',
      200: '#96a4d3',
      300: '#ab9ece',
      400: '#efebf7',
    },
    gradient: {
      buton: '#fbf8f6',
    },
    titleLight: '#e1e1e1',
    titleDark: '#050505',
    textDark: '#1b1b1b',
    textLight: '#e4e4e4',
  },
  breakpoints: {
    sm: '40rem', //640px
    md: '48rem', //768px
    lg: '64rem', //1024px
    xl: '80rem', //1280px
    '2xl': '96rem', //1536px
  },
});
