import {
  Heading,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useReducer } from 'react';
import { Store } from '../../../utils/Store';
import axios from 'axios';
import { getError } from '../../../utils/Utils';
import { LoadingBox, MessageBox } from '../../../components';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        ziare: action.payload,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
const ListaZiare = ({ api }) => {
  const [{ ziare, error, loading }, dispatch] = useReducer(reducer, {
    loading: false,
    error: '',
    ziare: [],
  });

  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await axios.get(`${api}/api/users/ziare`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    fetchData();
  }, [api, userInfo.token]);

  return (
    <Stack
      id={'listaZiare'}
      w={'full'}
      borderRadius={'1.5rem'}
      bg={'brand.100'}
      boxShadow={'0 0 32px rgba(0,0,0,0.3)'}
      px={'3rem'}
      py={'2rem'}
      gap={'3rem'}
    >
      <Heading as={'h2'} fontSize={'1.75rem'}>
        Listă publicații
      </Heading>
      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox status={'error'}>{error}</MessageBox>
      ) : (
        <TableContainer
          w={'full'}
          color={'#030303'}
          overflowY={'auto'}
          h={'500px'}
        >
          <Table size={'lg'} variant={'striped'} colorScheme="blackAlpha">
            <Tbody>
              {ziare.map((ziar) => (
                <Tr key={ziar.nume}>
                  <Td>
                    <Image w={'80px'} src={`${ziar.poza}`} alt={ziar.nume} />
                  </Td>
                  <Td>
                    <Text
                      w={'300px'}
                      overflow={'hidden'}
                      textOverflow={'ellipsis'}
                      whiteSpace={'nowrap'}
                    >
                      {ziar.nume}
                    </Text>
                  </Td>
                  <Td>{ziar.link}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
};

export default ListaZiare;
