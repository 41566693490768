import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Store } from '../utils/Store';

function PayedRoute({ children }) {
  const { state } = useContext(Store);
  const { userInfo } = state;
  return userInfo.isPayed || userInfo.isAdmin ? (
    children
  ) : (
    <Navigate to="/pachete" />
  );
}

export default PayedRoute;
