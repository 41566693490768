import { Box, Stack } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-scroll';

const StickyMenu = () => {
  const menuItems = [
    {
      id: 'dateGenerale',
      text: 'Date Generale',
    },
    {
      id: 'changePassword',
      text: 'Schimbă Parola',
    },
    {
      id: 'listaZiare',
      text: 'Listă Publicații',
    },
  ];
  return (
    <Stack
      w={{ base: 'full', lg: '300px' }}
      position={{ base: 'unset', lg: 'fixed' }}
      borderRadius={'1.5rem'}
      bg={'brand.100'}
      boxShadow={'0 0 32px rgba(0,0,0,0.3)'}
      px={'1.5rem'}
      py={'3rem'}
      gap={'1rem'}
    >
      {menuItems.map((item) => (
        <Link
          key={item.id}
          to={item.id}
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
        >
          <Box
            px={'1rem'}
            py={'.5rem'}
            borderRadius={'1rem'}
            _hover={{ bg: 'brand.400', cursor: 'pointer' }}
            fontSize={'1.15rem'}
            w={'fit-content'}
            fontWeight={'bold'}
          >
            {item.text}
          </Box>
        </Link>
      ))}
    </Stack>
  );
};

export default StickyMenu;
